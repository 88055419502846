<template >
    <user-dashboard-layout>
        <v-container fluid class="ma-1">
            <v-row>
                <v-col>
                    <h1>Claims table</h1>
                </v-col>

                <v-col class="text-right">
                    <v-btn
                        color="primary"
                        @click="parseSftp('ASSIGMENT')"
                        :loading="btnLoading.ASSIGMENT"
                        class="mx-4"
                    > fetch assignments
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="parseSftp('STATUS')"
                        :loading="btnLoading.STATUS"
                        class="mx-4"
                    > fetch statuses
                    </v-btn>
                  <v-btn
                      color="primary"
                      @click="parseSftp('ESTIMATE')"
                      :loading="btnLoading.ESTIMATE"
                      class="mx-4"
                  > fetch ESTIMATE
                  </v-btn>

                </v-col>
            </v-row>
            <v-row dense>
                <v-text-field
                    dense
                    outlined
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    v-model="inputdate"
                    hide-details
                    v-on:input="dateForSearch"
                ></v-text-field>
            </v-row>
            <v-row>
                <v-col>
                  <v-data-table v-if="table && table.length > 0"
                                :headers="headers"
                                :items="table"
                                :items-per-page="15"
                                class="elevation-1"
                                item-key="id"
                                dense
                                :search="search"
                  >
                    <template v-slot:item.date_of_loss="{ item }" >
                      {{ item.date_of_loss | americanDateFormat }}
                    </template>

                    <template v-slot:item.received_date="{ item }" >
                      {{ item.received_date | americanDateFormat }}
                    </template>

                    <template v-slot:item.planned_inspection_date="{ item }" >
                      {{ item.planned_inspection_date | americanDateFormat }}
                    </template>

                    <template v-slot:item.ia_reviewed_date="{ item }" >
                      {{ item.ia_reviewed_date | americanDateFormat }}
                    </template>

                    <template v-slot:item.reviewed_date="{ item }" >
                      {{ item.reviewed_date | americanDateFormat }}
                    </template>

                    <template v-slot:item.approved_date="{ item }" >
                      {{ item.approved_date | americanDateFormat }}
                    </template>
                    <template v-slot:item.qa_approved_date="{ item }" >
                      {{ item.qa_approved_date | americanDateFormat }}
                    </template>
                    <template v-slot:item.notified="{ item }" >
                      {{ item.notified | americanDateFormat }}
                    </template>
                    <template v-slot:item.contacted ="{ item }" >
                      {{ item.contacted  | americanDateFormat }}
                    </template>
                    <template v-slot:item.inspected ="{ item }" >
                      {{ item.inspected  | americanDateFormat }}
                    </template>
                    <template v-slot:item.returned ="{ item }" >
                      {{ item.returned  | americanDateFormat }}
                    </template>

                    <template v-slot:item.phone ="{ item }" >
                      {{ item.phone  | phoneFormat }}
                    </template>
                    <template v-slot:item.home_phone ="{ item }" >
                      {{ item.home_phone  | phoneFormat }}
                    </template>
                   <template v-slot:item.mobile_phone ="{ item }" >
                      {{ item.mobile_phone  | phoneFormat }}
                    </template>


                  </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "@/layouts/UserDashboardLayout";
import ClaimsApi from '@/api/Claims';
import dayjs from 'dayjs'

export default {
    name: "ClaimsIndex",
    components: {UserDashboardLayout},

    data: function () {
        return {
            table: [],
            src: 'ASSIGMENT',
            search: '',
            btnLoading: {
                ASSIGMENT: false,
                STATUS:false,
                ESTIMATE:false
            },
            inputdate:''
        }
    },
    mounted() {
        this.fetchData()
    },
    computed: {

      // eslint-disable-next-line vue/return-in-computed-property

        //TODO maybe easy get headers from BD comments (see Claims migration in ->comment())
        headers() {
            return [
                {"text": "id", "value": "id"},
                {"text": "Claim #", "value": "claim_claim"},
                {"text": "Policy #", "value": "policy_number"},  /** Missing **/
                {"text": "Dataset", "value": "dataset"},  /** Missing **/
                {"text": "Attn", "value": "attention"},  /** Missing **/
                {"text": "Total", "value": "total"},
                {"text": "CAT Code", "value": "cat_code"},
                {"text": "Service Type", "value": "service_type"},  /** Job type in example **/
                {"text": "Loss Type", "value": "loss_type"},
                {"text": "Insured name", "value": "insured"},
                {"text": "Inspected phone", "value": "phone"},
                {"text": "Inspected home phone", "value": "home_phone"},
                {"text": "Inspected mobile phone", "value": "mobile_phone"},
                {"text": "Address", "value": "address"}, /** Missing **/
                {"text": "City", "value": "city"},
                {"text": "State", "value": "state"},
                {"text": "Zip", "value": "zip"}, /** Missing **/
                {"text": "Date of Loss", "value": "date_of_loss"},
                {"text": "Notified", "value": "notified"},
                {"text": "Delivered", "value": "delivered"}, /** Missing **/
                {"text": "Contacted", "value": "contacted"},
                {"text": "Planned inspection date", "value": "planned_inspection_date"}, /** Missing **/
                {"text": "Appointment", "value": "appointment"}, /** Missing **/
                {"text": "Inspected", "value": "inspected"},
                {"text": "Approved Date", "value": "approved_date"},  /** Missing **/
                {"text": "Claim Rep", "value": "claim_rep"},
                {"text": "Claim Rep Code", "value": "claim_rep_code"},  /** Missing **/
                {"text": "XN Address", "value": "xn_address"},
                {"text": "Photo count", "value": "photo_count"},
                {"text": "Latitude", "value": "latitude"},  /** Missing **/
                {"text": "Longitude", "value": "longitude"}, /** Missing **/
                {"text": "Collaborator", "value": "collaborator"}, /** Missing **/
                {"text": "Collaboration Started", "value": "collaborator_started"}, /** Missing **/
                {"text": "Collaboration Completed", "value": "collaborator_completed"}, /** Missing **/
                /*{"text": "Received", "value": "received"},*/
                {"text": "Received Date", "value": "received_date"}, /** not needed **/
                {"text": "QA Approved Date", "value": "qa_approved_date"},
                {"text": "QA Approval", "value": "qa_approval"},
                {"text": "Returned", "value": "returned"},
                {"text": "Reviewed Date", "value": "reviewed_date"},
                {"text": "IA Reviewer", "value": "ia_reviewer"},  /** Missing **/
                {"text": "IA Review Status", "value": "ia_review_status"},
                {"text": "IA Reviewed Date", "value": "ia_reviewed_date"}, /** Missing **/
                {"text": "Policy Type", "value": "policy_type"},
                {"text": "Desk Adjuster", "value": "desk_adjuster"},  /** Missing **/
                {"text": "R", "value": "r"},
                {"text": "XactAnalysis", "value": "xactanalysis"},
                {"text": "Rooms", "value": "rooms"},  /** Missing **/
                {"text": "Workflow", "value": "workflow"},
                {"text": "Days to Return", "value": "days_to_return"},
                {"text": "Notes", "value": "notes"},   /** Missing **/
                {"text": "Documents", "value": "documents"},   /** Missing **/
                {"text": "Sketches", "value": "sketches"},   /** Missing **/
                {"text": "Assignee", "value": "assignee"},
                {"text": "Invoice Status", "value": "invoice_status"},
                // {"text": "Created at", "value": "created_at"},
                // {"text": "Updated at", "value": "updated_at"}
            ]
        }
    },
    filters: {
        americanDateFormat: (date) => {
            if (!date){
                return null;
            }

          return dayjs(date).format('MM/DD/YYYY')
        },

        phoneFormat:(date)=>{
          if (!date){
            return null;
          }
          let temp=date.replace('-','');
           temp=temp.replace('-','');
           temp=temp.replace('-','');
           temp=temp.replace('-','');
           temp=temp.replace('-','');

          return temp

        }
    },
    methods: {
        async fetchData() {
            ClaimsApi.all().then(resp => {
                this.table =  resp.data;
            })
        },
        parseSftp(src) {
            this.btnLoading[src] = true
            this.reportParse = null
            window.axios.get('/api/sftp_and_parse/' + src).then(res => {
                this.reportParse = res.data
                this.fetchData().then(()=>{
                    this.btnLoading[src] = false
                })
            })
        },
      dateForSearch(){
        let that=this;
        if(this.inputdate.includes('/')) {
          let format = dayjs(this.inputdate).format('YYYY-MM-DD');
          console.log(format)
          if (format !== "Invalid Date") {
            that.search = format;
          } else {
            this.search = this.inputdate;
          }
        }else {
          this.search = this.inputdate;
        }
      },
    }

}
</script>

<style scoped>

</style>
