export default [
  {
    to: {name: 'dashboard.index'},
    icon: 'mdi-home',
    text: 'Dashboard',
  },
  {
    to: {name: 'dashboard.claims'},
    icon: 'mdi-table',
    text: 'Claims',
  },
  {
    to: {name: 'dashboard.claims2'},
    icon: 'mdi-table',
    text: 'Claims2',
  },
  {
    to: {name: 'dashboard.imported'},
    icon: 'mdi-shredder',
    text: 'debugging log',
  },
  {
    to: {name: 'dashboard.reparsed'},
    icon: 'mdi-table',
    text: 'Reparce',
  },
  // {
  //     to: {name: 'dashboard.billing'},
  //     icon: 'mdi-credit-card',
  //     text: 'Billing',
  // },
  // {
  //     to: {name: 'dashboard.billing.select-plan'},
  //     icon: 'mdi-credit-card',
  //     text: 'Select Plan',
  // },
]
